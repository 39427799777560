import './App.css';

import React from 'react';
import {BrowserRouter as Router, Link, Route, Switch} from 'react-router-dom';

import club from './club.png';
import diamond from './diamond.png';
import heart from './heart.png';
import logo from './logo.svg';
import spade from './spade.png';

const devUrls = {
  'grv': 'http://penguin.linux.test:8080/',
  'dheeraj': 'http://localhost:8080/',
}

const webUrl = 'https://onlinejudgement-backend.wl.r.appspot.com/';
var devMode = null;
const devUrl = 'http://localhost:8080/';

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      suit: props.suit,
      morespace: props.morespace,
    };
  }

  getAlphabet() {
    let val = [
      '', 'invalid', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q',
      'K', 'A'
    ];
    return val[this.props.value];
  }

  render() {
    let cardsuit = <div className = 'card'>{this.getAlphabet()}<
        /div>;
    if (this.props.value > 1) {
      if (this.props.suit == 1) {
        cardsuit =
          <div className="cardclub">
            {this.getAlphabet()}
          </div>;
  }
  if(this.props.suit == 2) {
    cardsuit = <div className = 'carddiamond'>{this.getAlphabet()}<
        /div>;
      }
      if (this.props.suit == 3) {
        cardsuit =
          <div className="cardheart">
            {this.getAlphabet()}
          </div>;
  }
  if(this.props.suit == 4) {
        cardsuit =
          <div className='cardspade'>
            {this.getAlphabet()}
          </div>;
      }
    }
    if (this.props.morespace == 1) {
      return (
        <button className="cardmorespace" onClick={() => this.props.onClick()}>
          {cardsuit}
        </button>
      );
  }
    return (
      <button className='card' onClick={() => this.props.onClick()}>
        {cardsuit}
      </button>
    );
  }
}

class CurrentHand extends React.Component {
  constructor(props) {
    super(props);
  }

  getCardsInHand() {
    let cardList = Array(this.props.numcards);
    for (let i = 0; i < this.props.numcards; i++) {
      cardList[i] = <Card value={this.props.cardNumbers[i]} suit={this.props.suits[i]} morespace={false} onClick={() => this.props.onClick(i)}/>
}
return cardList;
}

getTrumpString() {
  if (this.props.trump == 'C') {
    return 'Club';
  }
  if (this.props.trump == 'S') {
    return 'Spade';
  }
  if (this.props.trump == 'D') {
    return 'Diamond';
  }
  if (this.props.trump == 'H') {
    return 'Heart';
  }
  if (this.props.trump == 'N') {
    return 'No Trump';
  }

  return '';
}

render() {
  const heading = 'Your Hand';

  return (<div><div className = 'board-row'>{
      this.getCardsInHand()}</div>
      </div>);
}
}

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      turnToPlay: props.turntoplay,
      cardPlayed: props.cardplayed,
      scoreTillNow: props.scoretillnow,
      turnsw: props.turnsw,
      bid: props.bid,
    };
  }

  render() {
    if (this.props.turntoplay || this.props.turntobid) {
      let toplay = 'turn';
      return (
          <button className = 'playertoplay'>{
              this.props.name}<div className = 'smallstatus'> {
            this.props.turnsw
          } / {this.props.bid}</div > <
          /button>
      );
    }
    return (
      <button className="player">
        {this.props.name}
        <div className="smallstatus"> {this.props.turnsw} /{
              this.props.bid}</div>
      </button>);
    }
  }

  class PlayerScore extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: props.name,
        scoreTillNow: props.scoretillnow,
      };
    }

    render() {
      return <button className = 'player'>{
          this.props.scoretillnow}<div className = 'smallstatus'>{
          this.props.name}</div>
      </button>;
    }
  }

  class HandsPlayed extends React.Component {
    constructor(props) {
      super(props);
    }

    renderHandPlayed(idx) {
    return <Card value={this.props.cardNumbers[idx]} suit={this.props.suits[idx]} morespace={1}/>;
  }

  getHandsPlayed() {
    let handsPlayed = [];
    for (let i = 0; i < this.props.numplayers; i++) {
      handsPlayed.push(this.renderHandPlayed(i));
    }
    return handsPlayed;
  }

  getNextRoundButton() {
    let allplayed = true;
    for (let i = 0; i < this.props.numplayers; i++) {
      if (this.props.cardNumbers[i] == 0) {
        allplayed = false;
      }
    }
    if (allplayed) {
      if (this.props.player_name == this.props.next_player) {
        let nextbutton = 'Start Next Turn';
        return (
          <button className='nextturnbutton' onClick={() => this.props.onClick()}>
            {nextbutton}
          </button>
        );
    }
    else {
      return (
          <div>
          </div>
        );
      }
    } else {
      return (
        <div>
        </div>);
    }
  }
  getTrumpCard() {
    let img = <div></div>;
    let imgcap = <figcaption>Trump</figcaption>;
    if (this.props.trump == 'C') {
      img = <img src = {club} className = 'trump'></img>;
    }
    if (this.props.trump == 'S') {
      img = <img src={spade} className='trump'></img>;
    }
    if (this.props.trump == 'D') {
      img = <img src = {diamond} className = 'trump'></img>;
    }
    if (this.props.trump == 'H') {
      img = <img src={heart} className='trump'></img>;
    }
    if (this.props.trump == 'N') {
      img = <img src='https://images-na.ssl-images-amazon.com/images/I/61o7KAJCx-L._AC_UX679_.jpg' className='trump'></img>
    }
    return (
      <button className='trumpbutton' disabled='true'>
        <figure>
          {img}
          {imgcap}
        </figure>
      </button>
    );
  }

  render() {
    const heading = 'Play Area';
    return (
        <div>
        <div>
          {this.getHandsPlayed()}
          {this.getTrumpCard()}
        </div>
        <div>
          {this.getNextRoundButton()}
        </div>
        </div>
    );
    }
  }

  class PlayerList extends React.Component {
    constructor(props) {
      super(props);
    }

    renderPlayer(name, toplay, tobid, turnsw, bid) {
    return <Player name={name} turntoplay={toplay} turntobid={tobid} turnsw={turnsw} bid={bid}/>;
  }

  getBid(playerid) {
    if (this.props.bids == null) {
      return "?";
    }
    let bid = this.props.bids[playerid];
    if (bid < 0) {
      return "?";
    }
    return bid;
  }


  getPlayers() {
    let playerList = Array();
    for (let i = 0; i < this.props.playerlist.length; i++) {
      let toplay = this.props.playerlist[i] == this.props.next_player ? 1 : 0;
      let tobid = this.props.playerlist[i] == this.props.next_bidder ? 1 : 0;
      let turnsw = this.props.turns_won[this.props.playerlist[i]];
      let bid = this.getBid(this.props.playerlist[i]);
      playerList.push(this.renderPlayer(this.props.playerlist[i], toplay, tobid, turnsw, bid));
    }
    return playerList;
  }

  render() {
    return (
        <div className='board-row'>
          {this.getPlayers()}
        </div>
    );
    }
  }

  class InputBid extends React.Component {
    constructor(props) {
      super(props);
    }

    AddInput(number) {
      return (
          <option value = {number} selected = {number == '0'}>{number}<
              /option>);
    }
    render() {

    let inputlist = [];
	    for (let i = 0; i <= this.props.maxbid; ++i) {
		    inputlist.push(this.AddInput(i.toString()));

	    }
      return (
          <form onSubmit = {this.props.onClick}><label>Bid:
              <select onChange =
               {
                 this.props.onChange
               } >
	      {inputlist}
	      </select>
          </label><input type = 'submit' value = 'Submit' />
          </form>
    );
  }
}

class BidList extends React.Component {
  getBidInput() {
    if (this.props.next_player == this.props.player_name) {
      return (<InputBid maxbid= {this.props.maxbid} onClick={this.props.onClick}
                        onChange={this.props.onChange}/>);
    }
    else {
      return (<div />);
    }
  }

  render() {
    return (<div><br /><div>{this.getBidInput()} {
        (this.props.next_player == this.props.player_name) && (<br />)} {
        (this.props.next_player == this.props.player_name) &&
        (<br />)}</div>
        </div>);
  }
}


class SwagatFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createGameHidden: false,
      joinGameHidden: false,
    };
  }

  toggleCreateGame() {
    if (this.state.createGameHidden) {
      this.state.createGameHidden = false;
      this.state.joinGameHidden = true;
    } else {
      this.state.createGameHidden = true;
    }
  }

  toggleJoinGame() {
    if (this.state.joinGameHidden) {
      this.state.joinGameHidden = false;
      this.state.createGameHidden = true;
    } else {
      this.state.joinGameHidden = true;
    }
  }

  render() {
    let heading = 'Welcome to Judgment. No Judgment passed ;)'
    let cgameurl = (devMode ? devUrl : webUrl) + 'createGame';
    let jgameurl = (devMode ? devUrl : webUrl) + 'joinGame';
    return (
        <div>
	   <h1 align='center'>{heading}</h1>
	    <br/>
	    <div>
        <CreateGame origin={this.props.origin} creategameurl = {cgameurl} />
	    </div>
      <JoinGame origin={this.props.origin} creategameurl = {jgameurl} />
	 </div>
    );
  }
}

class CreateGame extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="board-col" id="createGameDiv">
      <p>Khel Banao</p>
	     <form action={this.props.creategameurl} method='get' id='createGameForm'>
        <label for='userid'>Name: &emsp; &nbsp;&nbsp;&emsp;&nbsp;
           <input type='text' id='userid' name='userid'/>
	      </label><br/><br/>
        <label for='num_players'>Num Players: &nbsp;
          <input type='text' id='num_players' name='num_players'/>
	      </label><br/><br/>
        <input type='hidden' id='origin' name='origin' value={this.props.origin} />
      </form>
	    <button className="startpage" type='submit' form='createGameForm' value='Submit'>
        Create Game!
      </button>
        <br/>
        <br/>
      </div>
    );
  }
}

class JoinGame extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
    <div className='board-col' id='joinGameDiv'>
      <p>Khel Bigado</p>
	    <form action={this.props.creategameurl} method='get' id='joinGameForm'>
        <label className = 'usrlabel' for='userid'>Name: &emsp; &nbsp;&nbsp;
          <input type='text' id='userid' name='userid'/>
	      </label><br/><br/>
        <label for='gameid'>Game Id: &ensp;&nbsp;
          <input type='text' id='gameid' name='gameid'/>
	      </label><br/><br/>
        <input type='hidden' id='origin' name='origin' value={this.props.origin}/>
    </form>
	    <button className = 'startpage' type='submit' form='joinGameForm' value='Submit'>Join Game!</button>
    </div>
    );
  }
}

class GameNotStarted extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let waiting_heading = 'Waiting for all players to join';
    let game_heading = 'Gameid: ' + this.props.gameid;
    let tot_heading = 'Total Players: ' + this.props.num_players;
    let joined_heading = 'Players Joined: ' + this.props.players_joined;
    return (
      <div>
        <div className='gamestatus'>{waiting_heading}</div>
        <div className='gamestatus'>{game_heading}</div>
        <div className='gamestatus'>{tot_heading}</div>
        <div className='gamestatus'>{joined_heading}</div>
      </div>
    );
  }
}

class ScoreList extends React.Component {
  renderPlayer(name, score) {
    return <PlayerScore name={name} scoretillnow={score}/>;
  }

  getScores() {
    let scoresList = Array();
    if (this.props.scores == null) {
      return scoresList;
    }
    for (let i = 0; i < this.props.playerlist.length; i++) {
      let sc = this.props.scores[this.props.playerlist[i]];
      scoresList.push(this.renderPlayer(this.props.playerlist[i], sc));
    }
    return scoresList;
  }

  render() {
    return (<div><div className = 'board-row'>{
        this.getScores()}</div>
        </div>);
  }
}

class Game extends React.Component {
  intervalID;
  count = 0;
  lastFetch = 0;
  bidValInput = 0;

  constructor(props) {
    super(props);
    this.state = {
      numcardsinhand: 0,
      cardNumbersinhand: [],
      suitsinhand: [],
      numplayers: 0,
      cardNumbersPlayed: [],
      cardsplayed: [],
      suitsplayed: [],
      playerlist: [],
      next_player: '',
      turns_won: new Map(),
      bids: new Map(),
      scores: new Map(),
      played: 0,
      game_started: false,
      game_ended: false,
    };
  }

  getSuitNumberFromChar(c) {
    if (c == 'C') {
      return 1;
    }
    if (c == 'D') {
      return 2;
    }
    if (c == 'H') {
      return 3;
    }
    if (c == 'S') {
      return 4;
    }
    return 0;
  }

  getCharFromSuit(i) {
    if (i == 1) {
      return 'C';
    }
    if (i == 2) {
      return 'D';
    }
    if (i == 3) {
      return 'H';
    }
    if (i == 4) {
      return 'S';
    }
    return 'N';
  }

  getplayerlist() {
    //    return ['rasha', 'dheeraj', 'gaurav'];
  }

  getnextperson() {
    let i = 0;
    while (i < this.state.playerlist.length &&
           this.state.next_player != this.state.playerlist[i]) {
      i++;
    }

    i++;
    return this.state.playerlist[i % this.state.playerlist.length]
  }

  getPlayerIndex(playerlist, p) {
    let playerTurns = playerlist;
    for (let i = 0; i < playerTurns.length; i++) {
      if (playerTurns[i] == p) {
        return i;
      }
    }

    return -1;
  }

  componentDidMount() {
    this.intervalID = setInterval(this.getData.bind(this), 3000);
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
    this.getData();
  }

  componentWillUnmount() {
    /*
      stop getData() from continuing to run even
      after unmounting this component
    */
    clearInterval(this.intervalID);
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  onFocus =
      () => {
        this.intervalID = setInterval(this.getData.bind(this), 5000);
        let time_ms = Date.now();  // unixtimestamp (milliseconds)
        if (time_ms - this.lastFetch > 5000) {
          this.getData();
        }
      }

  onBlur =
      () => {
        clearInterval(this.intervalID);
      }

  getData() {
    this.count++;
    if (this.count > 360) {
      clearInterval(this.intervalID);
    }
    this.lastFetch = Date.now();  // unixtimestamp (milliseconds)
    fetch((devMode ? devUrl : webUrl) + 'khelstate?gameid=' + this.props.gameid)
        .then(res => res.json())
        .then(
            (data) => {
              if (data['game_started'] == 'no') {
                this.setState({
                  numcardsinhand: 0,
                  cardNumbersinhand: [],
                  suitsinhand: [],
                  numplayers: parseInt(data['num_players']),
                  cardNumbersPlayed: [],
                  cardsplayed: [],
                  suitsplayed: [],
                  playerlist: data['player_list'],
                  next_player: '',
                  turns_won: new Map(),
                  bids: new Map(),
                  scores: new Map(),
                  played: 0,
                  game_started: false,
                  trump: '',
                  game_ended: false,
                });
              } else {
                let playerName = this.props.user;
                let cardsAndSuits = data['players'][playerName];
                let playersList = data['player_list'];
                let numberofPlayers = playersList.length;
                let tmpcardNumbers = Array(cardsAndSuits.length);
                let tmpsuits = Array(cardsAndSuits.length);
                for (let i = 0; i < cardsAndSuits.length; i++) {
                  tmpcardNumbers[i] = Number(cardsAndSuits[i].split(':')[0]);
                  tmpsuits[i] = this.getSuitNumberFromChar(
                      cardsAndSuits[i].split(':')[1]);
                }

                let cardsPlayed = data['hands_played'];
                let tmpcardnumberplayed = [];
                let tmpsuitsplayed = [];
                for (let i = 0; i < numberofPlayers; i++) {
                  tmpcardnumberplayed[i] = 0;
                  tmpsuitsplayed[i] = 0;
                }

                for (let i = 0; i < cardsPlayed.length; i++) {
                  let playerIdx = this.getPlayerIndex(
                      playersList, cardsPlayed[i].split(':')[0]);
                  tmpcardnumberplayed[playerIdx] =
                      Number(cardsPlayed[i].split(':')[1]);
                  tmpsuitsplayed[playerIdx] =
                      this.getSuitNumberFromChar(cardsPlayed[i].split(':')[2]);
                }
                this.setState({
                  contacts: data,
                  numcardsinhand: cardsAndSuits.length,
                  cardNumbersinhand: tmpcardNumbers,
                  suitsinhand: tmpsuits,
                  numplayers: numberofPlayers,
                  cardsplayed: cardsPlayed,
                  cardNumbersPlayed: tmpcardnumberplayed,
                  suitsplayed: tmpsuitsplayed,
                  playerlist: playersList,
                  next_player: data['next_person'],
                  turns_won: data['turns_won'],
                  bids: data['bids'],
                  scores: data['scores'],
                  played: 0,
                  game_started: true,
                  trump: data['trump'],
                  game_ended: data['game_ended'],
                });
                console.log(this.state);
                this.forceUpdate();
              }
            },
            (error) => {
              console.log(error);
            })
        .catch(console.log);
  }

  getcardplayed(i) {
    return this.state.cardNumbersinhand[i] + ':' +
        this.getCharFromSuit(this.state.suitsinhand[i]);
  }

  checkNewTurn() {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    };
    const requestOptions = {
      method: 'POST',
    };
    let uurl = (devMode ? devUrl : webUrl) +
        'checknewturn?gameid=' + this.props.gameid;

    fetch(uurl, requestOptions).then(response => response).then(data => {});
    this.setState({
      next_player: '',
    });

    sleep(1500).then(() => {
      this.getData();
    })
  }

  updateDB(i) {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    };
    const requestOptions = {
      method: 'POST',
    };
    let uurl =
        (devMode ? devUrl : webUrl) + 'playturn?userid=' + this.props.user +
        '&cardplayed=' + this.getcardplayed(i) +
        '&gameid=' + this.props.gameid + '&nextperson=' + this.getnextperson();

    fetch(uurl, requestOptions).then(response => response).then(data => {
      if (data.status == 500) {
        alert('Play a valid card');
        this.played = 0;
      } else {
        if (data.staus == 501) {
          alert('You already played a hand');
          this.played = 0;
        }
      }
      sleep(1000).then(() => {
        this.getData();
      });
    });
  }

  handleClickHandPlayed(i) {
    /*const message = { name: 'dummyname', message: 'msg'};
    this.ws.send(JSON.stringify(message));*/
    if (this.getBidsDone(this.state.bids, this.state.playerlist) == false) {
      alert('Wait for bidding to be done');
    } else {
      if (this.state.next_player != this.props.user) {
        alert('Not your turn');
      } else {
        if (this.state.played == 1) {
          alert('played already');
        } else {
          if (this.state.playerlist.length == this.state.cardsplayed.length) {
            alert('Turn over. Start next turn');
          } else {
            this.state.played = 1;
            this.updateDB(i);
          }
        }
      }
    }
  }

  getBidsDone(bids, players) {
    for (let i = 0; i < players.length; i++) {
      if (bids[players[i]] < 0) {
        return false;
      }
    }

    return true;
  }

  handleBidSubmit(event) {
    this.updateBid();
    event.preventDefault();
  }

  updateBid() {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    };
    const requestOptions = {
      method: 'POST',
    };
    let uurl = (devMode ? devUrl : webUrl) +
        'submitbid?userid=' + this.props.user + '&bidval=' + this.bidValInput +
        '&gameid=' + this.props.gameid +
        '&numcards=' + this.state.numcardsinhand +
        '&nextperson=' + this.getnextperson();

    fetch(uurl, requestOptions).then(response => response).then(data => {
      if (data.status == 500) {
        alert('Enter a valid bid');
      }
      sleep(500).then(() => {
        this.getData();
      })
    });

    this.bidValInput = 0;
  }

  handleBidValChange(event) {
    this.bidValInput = event.target.value;
  }

  render() {
    let bids_done = this.getBidsDone(this.state.bids, this.state.playerlist);
    let next_player = '';
    let bid_next_player = '';
    if (bids_done) {
      next_player = this.state.next_player;
    } else {
      bid_next_player = this.state.next_player;
    }

    if (this.props.user && this.props.gameid) {
      if (this.state.game_started) {
        if (this.state.game_ended) {
          return (
            <div className = 'game'>
              <h2>Game Ended</h2>
              <p>
                <div className = 'game-board'>
                  <ScoreList playerlist = {this.state.playerlist}
                             scores = {this.state.scores} />
                </div>
              </p>
            </div>
          );
        } else {
        return (
            <div className = 'game'><p><div className = 'game-board'>
            <HandsPlayed numplayers = {this.state.numplayers} cardNumbers =
                 {this.state.cardNumbersPlayed} suits =
                     {this.state.suitsplayed} player_name =
                         {this.props.user} onClick =
                             {() => this.checkNewTurn()} next_player =
                                 {next_player} trump =
             {
               this.state.trump
             } />
          </div><br />
            </p>
        <p>
        <div className = 'game-board'>
          <PlayerList playerlist = {this.state.playerlist}
                      next_player = {next_player}
                      player_name = {this.props.user}
                      next_bidder = {bid_next_player}
                      bids = {this.state.bids}
		      turns_won = {this.state.turns_won}/>
            </div>
        <br/><br /><br /><br /><div className = 'game-board'>
            <CurrentHand numcards = {this.state.numcardsinhand} cardNumbers =
                 {this.state.cardNumbersinhand} suits =
                     {this.state.suitsinhand} onClick =
                         {i => this.handleClickHandPlayed(i)} trump =
             {
               this.state.trump
             } />
        </div><br /><br /><div className = 'game-board-col'>
            <BidList playerlist = {this.state.playerlist} bids =
                 {this.state.bids} maxbid = {this.state
                                                 .numcardsinhand} next_player =
                     {bid_next_player} player_name = {this.props.user} onClick =
                         {(event) => this.handleBidSubmit(event)} onChange =
             {
               (event) => this.handleBidValChange(event)
             } />
		</div><div className = 'game-board-col'>
            <ScoreList playerlist = {this.state.playerlist} scores =
             {
               this.state.scores
             } />
        </div>
            </p>
          <p>
          <div className="game-info">
          <div>{/*status */}</div><ol>{/* TODO */}</ol>
          </div>
            </p>
          </div>);
        }
      } else {
        return (<div className = 'game'><p><div className = 'game-board'>
                <GameNotStarted num_players =
                     {this.state.numplayers} players_joined = {
                         this.state.playerlist} gameid = {
                       this.props.gameid
                     } />
              </div></p>
          </div>);
      }
    } else {
      let origin = window.location.origin;
      return (
          <div className = 'game'>
            <div className = 'game-welcome'>
              <SwagatFlow playerlist = {this.state.playerlist}
                          scores = {this.state.scores} origin ={
        origin} />
            </div>
          </div>
      );

    }
  }
}

  // ========================================

  export default Game;
